import {
  GET_PRODUCTS,
  LOOP_THROUGH_PRODUCTS,
  RENDER_PRODUCTS,
  CHANGE_PAGE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  PRODUCTS_PER_PAGE,
  SEARCH_PRODUCTS,
  GET_BRANDS,
  GET_DIAGNOSTICS,
  GET_INDUSTRIES,
  GET_RECHERCHE,
  GET_EQUIPEMENT,
  GET_PRODUCTS_BY_TYPE,
  GET_PRODUCT
} from "./types";
import { firestore as db } from "../firebase/index";
import Fuse from "fuse.js";
import { isIncluded } from "../helpers/productsHelper";

export const getProducts = () => dispatch => {
  const products = [];
  db.collection("produits")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        const product = {
          id: doc.id,
          data: doc.data()
        };
        products.push(product);
      });
      dispatch({ type: GET_PRODUCTS, payload: { products } });
      dispatch(renderProducts(products));
    });
};

export const renderProducts = products => (dispatch, getState) => {
  const productsPerPage = getState().productsReducer.productsPerPage;
  const currentPage = getState().productsReducer.currentPage;
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  dispatch({ type: RENDER_PRODUCTS, payload: { products } });
  dispatch(loopThroughProducts(currentProducts));
};

export const loopThroughProducts = stateProducts => dispatch => {
  const products = [{}];
  let i = 0;
  for (let product of stateProducts) {
    if (!products[i].product1) {
      products[i].product1 = product;
    } else {
      if (!products[i].product2) {
        products[i].product2 = product;
      } else {
        products.push({});
        products[++i].product1 = product;
      }
    }
  }
  dispatch({ type: LOOP_THROUGH_PRODUCTS, payload: { products } });
};

export const changePage = page => (dispatch, getState) => {
  dispatch({ type: CHANGE_PAGE, payload: { page } });
  const products = getState().productsReducer.products;
  dispatch(renderProducts(products));
};

export const nextPage = page => (dispatch, getState) => {
  dispatch({ type: NEXT_PAGE, payload: { page } });
  const products = getState().productsReducer.products;
  dispatch(renderProducts(products));
};

export const previousPage = page => (dispatch, getState) => {
  dispatch({ type: PREVIOUS_PAGE, payload: { page } });
  const products = getState().productsReducer.products;
  dispatch(renderProducts(products));
};

export const changeProductsPerPage = productsPerPage => (
  dispatch,
  getState
) => {
  dispatch({ type: PRODUCTS_PER_PAGE, payload: { productsPerPage } });
  const products = getState().productsReducer.products;
  dispatch(renderProducts(products));
};

export const searchProducts = (searchTerm = "") => (dispatch, getState) => {
  const products = getState().productsReducer.products;
  const options = {
    shouldSort: true,
    threshold: 0.5,
    location: 0,
    distance: 1000,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["data.name", "data.options", "data.specs.value"]
  };
  console.log(Fuse);

  const fuse = new Fuse(products, options);
  console.log(fuse, Fuse);
  console.log(products);
  const results = searchTerm === "" ? products : fuse.search(searchTerm);
  dispatch({ type: SEARCH_PRODUCTS });
  dispatch(renderProducts(results));
  console.log(results);
};

export const getBrands = () => (dispatch, getState) => {
  const brands = [];
  db.collection("brand")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        const brand = {
          id: doc.id,
          data: doc.data()
        };
        brands.push(brand);
      });
      dispatch({ type: GET_BRANDS, payload: { brands } });
    });
};

export const getDiagnostics = () => dispatch => {
  const diagnostics = [];
  db.collection("diagnostic-invitro")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        const diagnostic = {
          id: doc.id,
          data: doc.data()
        };
        diagnostics.push(diagnostic);
      });
      dispatch({ type: GET_DIAGNOSTICS, payload: { diagnostics } });
    });
};

export const getIndustries = () => dispatch => {
  const industries = [];
  db.collection("industrie")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        const industrie = {
          id: doc.id,
          data: doc.data()
        };
        industries.push(industrie);
      });
      dispatch({ type: GET_INDUSTRIES, payload: { industries } });
    });
};

export const getRecherches = () => dispatch => {
  const recherches = [];
  db.collection("recherche-scientifique")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        const recherche = {
          id: doc.id,
          data: doc.data()
        };
        recherches.push(recherche);
      });
      dispatch({ type: GET_RECHERCHE, payload: { recherches } });
    });
};

export const getEquipement = () => dispatch => {
  const equipement = [];
  db.collection("equipement-de-laboratoire")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        const equip = {
          id: doc.id,
          data: doc.data()
        };
        equipement.push(equip);
      });
      dispatch({ type: GET_EQUIPEMENT, payload: { equipement } });
    });
};

export const getProductsByType = (brands, types) => dispatch => {
  dispatch({ type: GET_PRODUCTS_BY_TYPE });
  const products = [];
  db.collection("produits")
    .get()
    .then(snapshot => {
      snapshot.docs.forEach(doc => {
        if (brands.includes(doc.data().brand)) {
          if (!isIncluded(products, doc)) {
            const product = {
              id: doc.id,
              data: doc.data()
            };
            products.push(product);
          }
        }
        for (let type of types) {
          if (doc.data().type.includes(type)) {
            if (!isIncluded(products, doc)) {
              const product = {
                id: doc.id,
                data: doc.data()
              };
              products.push(product);
            }
          }
        }
      });
      dispatch(renderProducts(products));
      console.log(products);
    });
};

export const getProduct = id => dispatch => {
  db.collection("produits")
    .doc(id)
    .get()
    .then(snapshot => {
      const product = {
        id: snapshot.id,
        data: snapshot.data()
      };
      dispatch({ type: GET_PRODUCT, payload: { product } });
    });
};
