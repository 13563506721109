/**
 *
 * BlogItem
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
// import styled from 'styled-components';

/* eslint-disable react/prefer-stateless-function */
class BlogItem extends React.Component {
  state = {
    showImages: false
  };

  handleShow = () => {
    this.setState({ showImages: true });
  };

  render() {
    const { showImages } = this.state;
    const { id, title, image, createdAt, small, left } = this.props;
    return (
      <div
        data-aos={!left ? "fade-right" : "fade-left"}
        className={`col-md-${small ? "5" : "7"} mb-3`}
      >
        <img
          src={image}
          onLoad={this.handleShow}
          alt={title}
          style={{ display: "none" }}
        />
        <Link
          className="d-flex align-items-end bg-img-hero gradient-overlay-half-dark-v1 transition-3d-hover rounded-pseudo height-450"
          to={`/app/blogs/${id}`}
          style={showImages ? { backgroundImage: `url(${image})` } : {}}
        >
          <article className="w-100 text-center p-6">
            <h2 className="h4 text-white">{title}</h2>
            <div className="mt-4">
              <strong className="d-block text-white-70 mb-2">
                {createdAt}
              </strong>
            </div>
          </article>
        </Link>
      </div>
    );
  }
}

BlogItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  createdAt: PropTypes.string,
  small: PropTypes.bool,
  left: PropTypes.bool
};

export default BlogItem;
