/*
 *
 * ContactPage reducer
 *
 */

import {
  DEFAULT_ACTION,
  POST_CONTACT_START,
  POST_CONTACT_SUCCESS,
  POST_CONTACT_FAILED
} from "../actions/types";

export const initialState = {
  company: "",
  email: "",
  fullname: "",
  message: "",
  phone: "",
  loading: false,
  error: false,
  errorDetails: null,
  successDetails: null
};

function contactPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case POST_CONTACT_START:
      return {
        ...state,
        company: action.payload.company,
        email: action.payload.email,
        fullname: action.payload.fullname,
        message: action.payload.message,
        phone: action.payload.phone,
        loading: true,
        error: false
      };
    case POST_CONTACT_SUCCESS:
      return { ...state, successDetails: action.payload, loading: false };
    case POST_CONTACT_FAILED:
      return {
        ...state,
        errorDetails: action.payload,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}

export default contactPageReducer;
