import React, { Component } from "react";
import { connect } from "react-redux";

class ProductDescription extends Component {
  render() {
    return (
      <div className="mb-5">
        <h1 className="h3 font-weight-medium">
          {this.props.products.product.data.name}
        </h1>
        <p>{this.props.products.product.data.description}</p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(
  mapStateToProps,
  {}
)(ProductDescription);
