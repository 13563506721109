/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";

class MainSlider extends Component {
  render() {
    console.log(this.props);
    return (
      <img
        src={this.props.products.product.data.image}
        style={{ width: "100%" }}
      />
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(
  mapStateToProps,
  {}
)(MainSlider);
