import React, { Component } from "react";
import { connect } from "react-redux";

class Accordion extends Component {
  render() {
    const specsContent = this.props.products.product.data.specs.map(
      (spec, index) => (
        <div key={index} className="card-body">
          <p className="small mb-0">
            <strong>{spec.name}</strong>: {spec.value}
          </p>
        </div>
      )
    );
    const optionsContent = this.props.products.product.data.options.map(
      (option, index) => (
        <div key={index} className="card-body">
          <p className="small mb-0">{option}</p>
        </div>
      )
    );
    return (
      <div id="shopCartAccordion" className="accordion mb-5">
        <div className="card">
          <div className="card-header card-collapse" id="shopCardHeadingOne">
            <h3 className="mb-0">
              <button
                type="button"
                className="btn btn-link btn-block card-btn collapsed"
                data-toggle="collapse"
                data-target="#shopCardOne"
                aria-expanded="false"
                aria-controls="shopCardOne"
              >
                <span className="row align-items-center">
                  <span className="col-9">
                    <span className="media align-items-center">
                      <span className="media-body">
                        <span className="d-block font-size-1 font-weight-medium text-dark">
                          Spécifications techniques
                        </span>
                      </span>
                    </span>
                  </span>
                  <span className="col-3 text-right">
                    <span className="card-btn-arrow">
                      <span className="fa fa-arrow-down small" />
                    </span>
                  </span>
                </span>
              </button>
            </h3>
          </div>
          <div
            id="shopCardOne"
            className="collapse"
            aria-labelledby="shopCardHeadingOne"
            data-parent="#shopCartAccordion"
          >
            {specsContent}
          </div>
        </div>

        <div className="card">
          <div className="card-header card-collapse" id="shopCardHeadingTwo">
            <h4 className="mb-0">
              <button
                type="button"
                className="btn btn-link btn-block card-btn collapsed"
                data-toggle="collapse"
                data-target="#shopCardTwo"
                aria-expanded="false"
                aria-controls="shopCardTwo"
              >
                <span className="row align-items-center">
                  <span className="col-9">
                    <span className="media align-items-center">
                      <span className="media-body">
                        <span className="d-block font-size-1 font-weight-medium text-dark">
                          Options
                        </span>
                      </span>
                    </span>
                  </span>
                  <span className="col-3 text-right">
                    <span className="card-btn-arrow">
                      <span className="fa fa-arrow-down small" />
                    </span>
                  </span>
                </span>
              </button>
            </h4>
          </div>
          <div
            id="shopCardTwo"
            className="collapse"
            aria-labelledby="shopCardHeadingTwo"
            data-parent="#shopCartAccordion"
          >
            {optionsContent}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(mapStateToProps, {})(Accordion);
