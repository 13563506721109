import React, { Component } from "react";
import { connect } from "react-redux";
import { changeProductsPerPage } from "../actions";

class AboveProducts extends Component {
  onChange = e => {
    this.props.changeProductsPerPage(Number(e.target.value));
  };
  render() {
    return !this.props.products.products ? null : (
      <div className="container space-1">
        <div className="mb-4">
          <h1 className="h4 font-weight-medium">Les produits de TechnoLab</h1>
        </div>

        <div className="row align-items-center">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="d-flex align-items-center">
              <div className="dropdown bootstrap-select js-select dropdown-select mr-2 fit-width">
                <select
                  className="js-select selectpicker dropdown-select mr-2"
                  data-size="10"
                  data-width="fit"
                  data-style="btn-soft-secondary btn-xs"
                  onChange={this.onChange}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
                <span className="text-secondary font-size-1">
                  {`Nous affichons ${
                    this.props.products.numberOfProducts !== 0
                      ? this.props.products.currentPage *
                          this.props.products.productsPerPage -
                        this.props.products.productsPerPage +
                        1
                      : 0
                  }-${
                    this.props.products.currentPage *
                      this.props.products.productsPerPage >
                    this.props.products.numberOfProducts
                      ? this.props.products.numberOfProducts
                      : this.props.products.currentPage *
                        this.props.products.productsPerPage
                  } de ${this.props.products.numberOfProducts}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(mapStateToProps, {
  changeProductsPerPage
})(AboveProducts);
