import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="container space-2 text-center">
          <Link
            className="d-inline-flex align-items-center mb-3"
            to="/"
            aria-label="Technolab"
          >
            <img
              src="/assets/img/logos/Logo-Technolab-_2_-_1_-_1_%20(1).png"
              alt="Description"
              style={{ width: "400px" }}
            />
          </Link>

          <p>© Technolab. {new Date().getFullYear()} . All rights reserved.</p>

          <ul className="list-inline mb-0">
            <li className="list-inline-item mb-2 mb-sm-0">
              <a
                className="btn btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                href="#facebook"
              >
                <span className="fab fa-facebook-f btn-icon__inner" />
              </a>
            </li>

            <li className="list-inline-item mb-2 mb-sm-0">
              <a
                className="btn btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                href="#linkedin"
              >
                <span className="fab fa-linkedin btn-icon__inner" />
              </a>
            </li>
            <li className="list-inline-item mb-2 mb-sm-0">
              <a
                className="btn btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                href="#twitter"
              >
                <span className="fab fa-twitter btn-icon__inner" />
              </a>
            </li>
            <li className="list-inline-item mb-2 mb-sm-0">
              <a
                className="btn btn-icon btn-soft-dark btn-bg-transparent rounded-circle"
                href="#instagram"
              >
                <span className="fab fa-instagram btn-icon__inner" />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    );
  }
}
