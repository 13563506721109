import {
  GET_PRODUCTS,
  LOOP_THROUGH_PRODUCTS,
  RENDER_PRODUCTS,
  CHANGE_PAGE,
  PREVIOUS_PAGE,
  NEXT_PAGE,
  PRODUCTS_PER_PAGE,
  GET_BRANDS,
  GET_DIAGNOSTICS,
  GET_INDUSTRIES,
  GET_RECHERCHE,
  GET_EQUIPEMENT,
  GET_PRODUCT
} from "../actions/types";

const INIT_STATE = {
  products: null,
  productsForCards: null,
  productsPerPage: 5,
  currentPage: 1,
  numberOfProducts: null,
  brands: null,
  diagnostics: null,
  industries: null,
  recherches: null,
  equipement: null,
  product: null
};

export default (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload.products
      };
    case RENDER_PRODUCTS:
      return {
        ...state,
        numberOfProducts: action.payload.products.length
      };
    case LOOP_THROUGH_PRODUCTS:
      return {
        ...state,
        productsForCards: action.payload.products
      };
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload.page
      };
    case NEXT_PAGE:
      return {
        ...state,
        currentPage: action.payload.page
      };
    case PREVIOUS_PAGE:
      return {
        ...state,
        currentPage: action.payload.page
      };
    case PRODUCTS_PER_PAGE:
      return {
        ...state,
        productsPerPage: action.payload.productsPerPage
      };
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload.brands
      };
    case GET_DIAGNOSTICS:
      return {
        ...state,
        diagnostics: action.payload.diagnostics
      };
    case GET_INDUSTRIES:
      return {
        ...state,
        industries: action.payload.industries
      };
    case GET_RECHERCHE:
      return {
        ...state,
        recherches: action.payload.recherches
      };
    case GET_EQUIPEMENT:
      return {
        ...state,
        equipement: action.payload.equipement
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload.product
      };
    default:
      return {
        ...state
      };
  }
};
