/**
 *
 * OneBlogView
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { HTMLRenderer } from "ory-editor-renderer";

// The editor core
import "ory-editor-core/lib/index.css"; // we also want to load the stylesheets

// Require our ui components (optional). You can implement and use your own ui too!
import "ory-editor-ui/lib/index.css";

// Load some exemplary plugins:
import slate from "ory-editor-plugins-slate"; // The rich text area plugin
import "ory-editor-plugins-slate/lib/index.css"; // Stylesheets for the rich text area plugin

import parallax from "ory-editor-plugins-parallax-background"; // A plugin for parallax background images
import "ory-editor-plugins-parallax-background/lib/index.css"; // Stylesheets for parallax background images
// require('react-tap-event-plugin')() // react-tap-event-plugin is required by material-ui which is used by ory-editor-ui so we need to call it here
import spacer from "ory-editor-plugins-spacer";
import "ory-editor-plugins-spacer/lib/index.css";

// The image plugin
import { imagePlugin } from "ory-editor-plugins-image";
import "ory-editor-plugins-image/lib/index.css";

// The video plugin
import video from "ory-editor-plugins-video";
import "ory-editor-plugins-video/lib/index.css";

// The background plugin
import background from "ory-editor-plugins-background";
import { ModeEnum } from "ory-editor-plugins-background/lib/types/modeEnum";
import "ory-editor-plugins-background/lib/index.css";

// The html5-video plugin
import html5video from "ory-editor-plugins-html5-video";
import "ory-editor-plugins-html5-video/lib/index.css";

// The native handler plugin
import native from "ory-editor-plugins-default-native";

// The divider plugin
import divider from "ory-editor-plugins-divider";
import BlogArticle from "../../components/BlogArticle";
import { fetchBlog } from "../../actions";

const fakeImageUploadService = defaultUrl => (file, reportProgress) =>
  new Promise((resolve, reject) => {
    let counter = 0;
    const interval = setInterval(() => {
      counter++;
      reportProgress(counter * 10);
      if (counter > 9) {
        clearInterval(interval);
        alert(
          "This is a fake image upload service, please provide actual implementation via plugin properties"
        );
        resolve({ url: defaultUrl });
      }
    }, 500);
  });

const plugins = {
  content: [
    slate(),
    spacer,
    video,
    divider,
    html5video,
    imagePlugin({ imageUpload: fakeImageUploadService("/images/react.png") })
  ], // Define plugins for content cells. To import multiple plugins, use [slate(), image, spacer, divider]
  layout: [
    parallax({ defaultPlugin: slate() }),
    background({
      defaultPlugin: slate(),
      imageUpload: fakeImageUploadService("/images/sea-bg.jpg"),
      enabledModes:
        ModeEnum.COLOR_MODE_FLAG |
        ModeEnum.IMAGE_MODE_FLAG |
        ModeEnum.GRADIENT_MODE_FLAG
    })
  ],
  native // Define plugins for layout cells
};
/* eslint-disable react/prefer-stateless-function */
export class OneBlogView extends React.Component {
  componentWillMount = () => {
    const { id } = this.props.match.params;
    this.props.fetchBlog(id);
  };

  render() {
    const {
      oneBlogView: { blog, loaded }
    } = this.props;
    console.log(this.props);
    if (loaded) {
      console.log(JSON.parse(blog.content));
    }
    return (
      <div>
        <Helmet>
          <title>OneBlogView</title>
          <meta name="description" content="Description of OneBlogView" />
        </Helmet>
        {loaded && (
          <BlogArticle
            author={blog.author}
            content={JSON.parse(blog.content)}
            createdAt={blog.createdAt}
            image={blog.image}
            title={blog.title}
          >
            <div
              className="main main-raised"
              style={{
                marginBottom: "50px",
                paddingBottom: "50px",
                minHeight: "100vh",
                marginTop: "5px"
              }}
            >
              <div className="container" style={{ paddingTop: "100px" }}>
                <HTMLRenderer
                  state={JSON.parse(blog.content)}
                  plugins={plugins}
                />
              </div>
            </div>
          </BlogArticle>
        )
        // <HTMLRenderer state={JSON.parse(blog.content).cells[0]} />
        }
      </div>
    );
  }
}

OneBlogView.propTypes = {
  oneBlogView: PropTypes.object,
  fetchBlog: PropTypes.func,
  match: PropTypes.object
};

const mapStateToProps = ({ oneBlogView }) => ({
  oneBlogView
});

export default connect(mapStateToProps, { fetchBlog })(OneBlogView);
