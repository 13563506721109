/**
 *
 * ParticleEffect
 *
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ParticleEffects from "./lib/scripts/ParticleEffects";
import contactImage from "./contact-bg.jpg";
let particleEffect = null;
const ImageBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(${props => props.src});
  background-size: cover;
  background-color: #000;
`;
const ParticleHolder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  > canvas {
    overflow: hidden;
    position: absolute;
    left: ${props => props.left}vw;
    top: ${props => props.top}vh;
    width: 50vw;
    height: 50vh;
    @media (max-width: 1100px) {
      width: 100vw;
      heigth: 100vh;
      left: 0;
      top: 0;
    }
  }
`;
/* eslint-disable react/prefer-stateless-function */
class ParticleEffect extends React.Component {
  componentDidMount = () => {
    const { width, height, image, offsetLeft, offsetTop } = this.props;
    const { innerWidth } = window;
    window.addEventListener("resize", this.updateDimensions);

    if (innerWidth <= 1100) {
      particleEffect = new ParticleEffects(100, 100, image, 0, 0);
    } else {
      particleEffect = new ParticleEffects(
        width,
        height,
        image,
        offsetLeft,
        offsetTop
      );
    }

    this.particle = particleEffect.init();
  };

  updateDimensions = () => {
    const { innerWidth } = window;
    const { width, height, offsetLeft, offsetTop } = this.props;
    if (innerWidth <= 1100) {
      particleEffect.resize(100, 100, 0, 0);
    } else {
      particleEffect.resize(width, height, offsetLeft, offsetTop);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  render() {
    const { offsetLeft, offsetTop } = this.props;
    return (
      <div>
        <ImageBackground src={contactImage} />
        <ParticleHolder
          top={offsetTop}
          left={offsetLeft}
          id="particleContainer"
        />
      </div>
    );
  }
}

ParticleEffect.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetTop: PropTypes.number,
  image: PropTypes.string
};

export default ParticleEffect;
