/*
 *
 * OneBlogView actions
 *
 */

import {
  DEFAULT_ACTION,
  LOAD_ONE_BLOG_FAILED,
  LOAD_ONE_BLOG_START,
  LOAD_ONE_BLOG_SUCCESS
} from "./types";
import "moment/locale/fr";
import moment from "moment";
import { firestore } from "../firebase";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

const asyncFetchBlog = id =>
  firestore
    .collection("blog")
    .doc(id)
    .get();

export const fetchBlog = id => async dispatch => {
  console.log("test here");
  dispatch(loadOneBlog(id));
  try {
    const doc = await asyncFetchBlog(id);
    moment.locale("fr");
    const docData = doc.data();
    const blog = {
      id: doc.id,
      title: docData.title,
      author: docData.author,
      content: docData.content,
      image: docData.image,
      createdAt: moment(doc.data().createdAt.toDate()).calendar()
    };
    dispatch(loadOneBlogSuccess(blog));
  } catch (error) {
    dispatch(loadOneBlogFailed(error));
  }
};

export function loadOneBlog(id) {
  return {
    type: LOAD_ONE_BLOG_START,
    payload: id
  };
}

export function loadOneBlogSuccess(blog) {
  return {
    type: LOAD_ONE_BLOG_SUCCESS,
    payload: blog
  };
}

export function loadOneBlogFailed(error) {
  return {
    type: LOAD_ONE_BLOG_FAILED,
    payload: error
  };
}
