import { combineReducers } from "redux";
import productsReducer from "./productsReducer";
import contactPageReducer from "./contactPageReducer";
import oneBlogViewReducer from "./oneBlogViewReducer";
import blogListReducer from "./blogListReducer";
export default combineReducers({
  productsReducer: productsReducer,
  contactPage: contactPageReducer,
  oneBlogView: oneBlogViewReducer,
  blogList: blogListReducer
});
