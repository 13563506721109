/**
 *
 * BlogList
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import BlogGrid from "../../components/BlogGrid";
import { fetchBlogs } from "../../actions";
import blogpic2 from "./blogHero.jpg";
import ServiceHero from "../../components/ServicesHero";

/* eslint-disable react/prefer-stateless-function */
export class BlogList extends React.Component {
  componentWillMount = () => {
    const { blogList } = this.props;
    if (!blogList.loaded) {
      this.props.fetchBlogs();
    }
  };

  render() {
    console.log(this.props);
    const { blogList } = this.props;
    return (
      <div>
        <Helmet>
          <title>Technolab Blogs</title>
          <meta name="description" content="Description of BlogList" />
        </Helmet>
        <ServiceHero
          isBlog="yes"
          text="TechnoLab, Les actualités"
          image={blogpic2}
        />
        <div
          style={{
            marginBottom: "100px",
            paddingBottom: "100px",
            paddingTop: "100px"
          }}
        >
          <div className="container">
            <div className="w-md-80 w-lg-60 mb-11">
              <h1 className="font-weight-normal">
                TechnoLab -{" "}
                <span className="text-primary font-weight-semi-bold">
                  Actualités
                </span>
              </h1>
            </div>
            <BlogGrid
              blogs={blogList.blogs}
              loading={blogList.loading}
              loaded={blogList.loaded}
            />
          </div>
        </div>
      </div>
    );
  }
}

BlogList.propTypes = {
  fetchBlogs: PropTypes.func,
  blogList: PropTypes.object
};

const mapStateToProps = ({ blogList }) => ({
  blogList
});

export default connect(mapStateToProps, { fetchBlogs })(BlogList);
