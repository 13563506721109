/*
 *
 * ContactPage actions
 *
 */

import {
  DEFAULT_ACTION,
  POST_CONTACT_START,
  POST_CONTACT_SUCCESS,
  POST_CONTACT_FAILED
} from "./types";
import api from "axios";
import sgMail from "@sendgrid/mail";
export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export const postContact = (data, history) => async dispatch => {
  dispatch({ type: POST_CONTACT_START, payload: data });

  api({
    method: "post",
    url: `https://technolab.com.tn/mailer/send-mail`,
    data: { ...data, destination: "contact@technolab.com.tn" }
  })
    .then(res => {
      console.log(res);
      dispatch(postContactSuccess(res));
      history.push("/");
    })
    .catch(err => {
      console.log(err);
      dispatch(postContactFailed(err));
    });
};

export function postContactSuccess(res) {
  return {
    type: POST_CONTACT_SUCCESS,
    payload: res
  };
}

export function postContactFailed(error) {
  return {
    type: POST_CONTACT_FAILED,
    payload: error
  };
}
