import React, { Component } from "react";
import ProductMain from "../containers/ProductMain";
import Searchbar from "../components/Searchbar";
import AboveProducts from "../components/AboveProducts";

export default class Products extends Component {
  render() {
    return (
      <div>
        <Searchbar />
        <AboveProducts />
        <ProductMain />
      </div>
    );
  }
}
