import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Products from "./pages/Products";
import { Provider } from "react-redux";
import configureStore from "./store";
import Product from "./pages/Product";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ContactPage from "./pages/ContactPage";

import OneBlogView from "./pages/OneBlogView";
import BlogList from "./pages/BlogList";
// import BlogList from "../BlogList";
import AOS from "aos";
const store = configureStore();

class App extends React.Component {
  componentDidMount = () => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:

      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 800, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
    });
  };

  componentWillReceiveProps = () => {
    AOS.refresh();
  };
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/app/products" component={Products} />
              <Route exact path="/app/products/:id" component={Product} />
              <Route exact path="/app/contact" component={ContactPage} />
              <Route path="/app/blogs/:id" exact component={OneBlogView} />
              <Route exact path="/app/blogs" component={BlogList} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
