/* eslint-disable no-redeclare */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getBrands,
  getDiagnostics,
  getIndustries,
  getRecherches,
  getEquipement,
  getProductsByType,
  getProducts
} from "../actions";

class SideBar extends Component {
  componentDidMount() {
    this.props.getBrands();
    this.props.getDiagnostics();
    this.props.getIndustries();
    this.props.getRecherches();
    this.props.getEquipement();
  }

  onClick = () => {
    const brandItems = document.getElementsByName("brand");
    const brandProducts = [];
    for (var i = 0; i < brandItems.length; i++) {
      if (brandItems[i].type === "checkbox" && brandItems[i].checked === true)
        brandProducts.push(brandItems[i].value);
    }
    const diagnosticItems = document.getElementsByName("diagnostic");
    const typeProducts = [];
    for (var i = 0; i < diagnosticItems.length; i++) {
      if (
        diagnosticItems[i].type === "checkbox" &&
        diagnosticItems[i].checked === true
      )
        typeProducts.push(diagnosticItems[i].value);
    }
    const industriesItems = document.getElementsByName("industrie");
    for (var i = 0; i < industriesItems.length; i++) {
      if (
        industriesItems[i].type === "checkbox" &&
        industriesItems[i].checked === true
      )
        typeProducts.push(industriesItems[i].value);
    }
    const recherchesItems = document.getElementsByName("recherche");
    for (var i = 0; i < recherchesItems.length; i++) {
      if (
        recherchesItems[i].type === "checkbox" &&
        recherchesItems[i].checked === true
      )
        typeProducts.push(recherchesItems[i].value);
    }
    const equipementItems = document.getElementsByName("equipement");
    for (var i = 0; i < equipementItems.length; i++) {
      if (
        equipementItems[i].type === "checkbox" &&
        equipementItems[i].checked === true
      )
        typeProducts.push(equipementItems[i].value);
    }
    if (brandProducts.length === 0 && typeProducts.length === 0) {
      this.props.getProducts();
    } else this.props.getProductsByType(brandProducts, typeProducts);
  };

  clear = () => {
    const diagnosticItems = document.getElementsByName("diagnostic");
    for (var i = 0; i < diagnosticItems.length; i++) {
      if (diagnosticItems[i].type === "checkbox")
        diagnosticItems[i].checked = false;
    }
    const industrieItems = document.getElementsByName("industrie");
    for (var i = 0; i < industrieItems.length; i++) {
      if (industrieItems[i].type === "checkbox")
        industrieItems[i].checked = false;
    }
    const rechercheItems = document.getElementsByName("recherche");
    for (var i = 0; i < rechercheItems.length; i++) {
      if (rechercheItems[i].type === "checkbox")
        rechercheItems[i].checked = false;
    }

    this.props.getProducts();
  };

  render() {
    let diagnosticContent = this.props.products.diagnostics
      ? this.props.products.diagnostics.map((diagnostic, index) => (
          <div
            key={index}
            className="custom-control custom-checkbox font-size-1 text-lh-md mb-2 mr-1"
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`diagnostic${index}`}
              name="diagnostic"
              value={diagnostic.id}
            />
            <label
              className="custom-control-label"
              htmlFor={`diagnostic${index}`}
            >
              {diagnostic.data.name}
            </label>
          </div>
        ))
      : null;
    let industriesContent = this.props.products.industries
      ? this.props.products.industries.map((industrie, index) => (
          <div
            key={index}
            className="custom-control custom-checkbox font-size-1 text-lh-md mb-2 mr-2"
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`industrie${index}`}
              name="industrie"
              value={industrie.id}
            />
            <label
              className="custom-control-label"
              htmlFor={`industrie${index}`}
            >
              {industrie.data.name}
            </label>
          </div>
        ))
      : null;
    let recherchesContent = this.props.products.recherches
      ? this.props.products.recherches.map((recherche, index) => (
          <div
            key={index}
            className="custom-control custom-checkbox font-size-1 text-lh-md mb-2 mr-2"
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`recherche${index}`}
              name="recherche"
              value={recherche.id}
            />
            <label
              className="custom-control-label"
              htmlFor={`recherche${index}`}
            >
              {recherche.data.name}
            </label>
          </div>
        ))
      : null;

    return (
      <div className="col-lg-4">
        <div className="pl-lg-4">
          <div className="card p-4">
            <div className="border-bottom pb-4 mb-4">
              <label className="form-label mb-2">Beckman Coulter</label>
              <div className="custom-control custom-checkbox font-size-1 text-lh-md mb-2 mr-2">
                <div className="row">{diagnosticContent}</div>
              </div>
            </div>

            <div className="border-bottom pb-4 mb-4">
              <label className="form-label mb-2">Mettler Toledo</label>
              <div className="custom-control custom-checkbox font-size-1 text-lh-md mb-2 mr-2">
                <div className="row">{industriesContent}</div>
              </div>
            </div>

            <div className="border-bottom pb-4 mb-4">
              <label className="form-label mb-2">Sciex</label>
              <div className="custom-control custom-checkbox font-size-1 text-lh-md mb-2 mr-2">
                <div className="row">{recherchesContent}</div>
              </div>
            </div>
            <div className="row mx-gutters-2">
              <div className="col-6">
                <button
                  type="submit"
                  className="btn btn-block btn-sm btn-soft-secondary"
                  onClick={this.clear}
                >
                  Réinitialiser
                </button>
              </div>
              <div className="col-6">
                <button
                  type="submit"
                  className="btn btn-block btn-sm btn-primary"
                  onClick={this.onClick}
                >
                  Sauvegarder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(mapStateToProps, {
  getBrands,
  getDiagnostics,
  getIndustries,
  getRecherches,
  getEquipement,
  getProductsByType,
  getProducts
})(SideBar);
