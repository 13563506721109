/**
 *
 * ServicesHero
 *
 */

import React from "react";
import PropTypes from "prop-types";
// import styled from 'styled-components';

/* eslint-disable react/prefer-stateless-function */
const ServicesHero = ({ text, image, isBlog }) => {
  return (
    <main id="content" role="main">
      <div
        className="d-md-flex bg-img-hero hero"
        style={{ backgroundImage: `url(${image})`, height: "100vh" }}
      >
        <div className="container d-md-flex align-items-md-center height-md-100vh position-relative space-top-2 space-bottom-3 space-top-md-3 space-top-lg-2 space-bottom-md-0">
          <div className="w-lg-65 mb-5">
            <div className="w-lg-65 w-xl-50 mb-5">
              <h1
                className="display-4 text-white"
                style={{ fontSize: isBlog === "yes" ? "100px" : "3.5rem" }}
              >
                {text}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

ServicesHero.propTypes = { text: PropTypes.string, image: PropTypes.string };

export default ServicesHero;
