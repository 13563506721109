/*
 *
 * OneBlogView reducer
 *
 */

import {
  DEFAULT_ACTION,
  LOAD_ONE_BLOG_FAILED,
  LOAD_ONE_BLOG_START,
  LOAD_ONE_BLOG_SUCCESS
} from "../actions/types";

export const initialStates = {
  id: "",
  blog: null,
  loading: false,
  error: false,
  errorDetails: null,
  loaded: false
};

function oneBlogViewReducer(state = initialStates, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case LOAD_ONE_BLOG_START:
      return {
        ...state,
        id: action.payload,
        loading: true,
        loaded: false,
        error: false
      };
    case LOAD_ONE_BLOG_SUCCESS:
      return { ...state, blog: action.payload, loaded: true, loading: false };
    case LOAD_ONE_BLOG_FAILED:
      return {
        ...state,
        errorDetails: action.payload,
        loading: false,
        loaded: false,
        error: true
      };
    default:
      return state;
  }
}

export default oneBlogViewReducer;
