import React, { Component } from "react";
import MainSlider from "../components/MainSlider";
import { connect } from "react-redux";

class ProductImage extends Component {
  render() {
    return !this.props.products.product ? null : (
      <div className="col-lg-7 mb-7 mb-lg-0">
        <div className="pr-lg-4">
          <MainSlider />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(
  mapStateToProps,
  {}
)(ProductImage);
