/**
 *
 * BlogGrid
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import BlogItem from '../BlogItem';
// import styled from 'styled-components';
const staticBlogs = [
  {
    left: true,
    small: false,
    image: '',
    title: 'Blog Article',
    id: '1',
    createdAt: '',
  },
  {
    left: true,
    small: false,
    image: '',
    title: 'Blog Article',
    id: '1',
    createdAt: '',
  },
];
/* eslint-disable react/prefer-stateless-function */
class BlogGrid extends React.Component {
  renderBlogs = blogs => {
    let parity = blogs.length % 2 === 1;
    let doubleParity = false;
    const renderedBlogs = [];
    blogs.map((blog, key) => {
      if (parity) doubleParity = !doubleParity;
      parity = !parity;
      renderedBlogs.unshift(
        <BlogItem
          key={key}
          left={parity}
          small={doubleParity}
          image={blog.image}
          title={blog.title}
          id={blog.id}
          createdAt={blog.createdAt}
        />,
      );
    });
    return renderedBlogs;
  };

  render() {
    const { blogs, loading, loaded } = this.props;
    return (
      <div className="row mx-gutters-2">
        {loading && !loaded
          ? this.renderBlogs([...staticBlogs])
          : this.renderBlogs([...blogs])}
      </div>
    );
  }
}

BlogGrid.propTypes = {
  blogs: PropTypes.array,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
};

export default BlogGrid;
