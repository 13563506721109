/**
 *
 * ContactForm
 *
 */

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleReactValidator from "simple-react-validator-multilang";
import PropTypes from "prop-types";
// import styled from 'styled-components';

/* eslint-disable react/prefer-stateless-function */
class ContactForm extends React.Component {
  state = {};

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount = () => {
    this.validator = new SimpleReactValidator({}, "fr");
  };

  handleSubmit = () => {
    if (this.validator.allValid() && !this.props.loading) {
      const mailData = {
        fullName: this.state.fullname,
        email: this.state.email,
        phone: this.state.phone,
        organisation: this.state.company,
        message: this.state.message
      };

      this.props.callback(mailData);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div className="container space-3 space-md-3">
        <div className="row justify-content-start">
          <div
            className="col-sm-10 col-md-8 col-lg-8 col-xl-7"
            style={{ opacity: 0.9 }}
          >
            <div className="card border-0 shadow-sm">
              <div className="card-body p-7">
                <div className="mb-4">
                  <h2 className="font-weight-normal mb-0">Contactez nous</h2>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-7 text-center">
                    <div className="px-lg-3">
                      <span className="btn-soft-primary mb-5 btn btn-icon btn-lg  rounded-circle">
                        <span className="fas fa-map-marker-alt fa-2x btn-icon__inner" />
                      </span>
                    </div>
                    <a href="https://www.google.com/maps/dir//TechnoLab,+10+Rue+des+Ottomans,+Ariana+2037/@36.852858,10.1006652,12z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x12fd356898822fd1:0xe5770b8f50808c5e!2m2!1d10.1707052!2d36.8528785">
                      <p className="mb-md-0">
                        10, Rue des Ottomans 2091, 2037 El Manzah 5 Tunisie
                      </p>
                    </a>
                  </div>
                  <div className="col-md-6 mb-7 text-center">
                    <div className=" px-lg-3">
                      <span className="btn-soft-primary mb-5 btn btn-icon btn-lg  rounded-circle">
                        <span className="fas fa-envelope fa-2x btn-icon__inner " />
                      </span>
                    </div>
                    <p className="mb-md-0">contact@technolab.com.tn</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="fullname"
                        value={this.state.fullname}
                        onChange={this.handleChange}
                        placeholder="Nom et Prénom"
                        aria-label="Nom et Prénom"
                      />
                      {this.validator &&
                        this.validator.message(
                          "Nom et Prénom",
                          this.state.fullname,
                          "required|alpha_space"
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Email"
                        aria-label="Email"
                      />
                      {this.validator &&
                        this.validator.message(
                          "Email",
                          this.state.email,
                          "required|email"
                        )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder="Téléphone"
                        aria-label="Téléphone"
                      />
                      {this.validator &&
                        this.validator.message(
                          "Téléphone",
                          this.state.phone,
                          "required|phone"
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="company"
                        value={this.state.company}
                        onChange={this.handleChange}
                        placeholder="Organisation"
                        aria-label="Organisation"
                      />
                      {this.validator &&
                        this.validator.message(
                          "Organisation",
                          this.state.company,
                          "required|alpha_num_space"
                        )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Votre message"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                    />
                    {this.validator &&
                      this.validator.message(
                        "Message",
                        this.state.message,
                        "required"
                      )}
                  </div>
                </div>
                <div className="media align-items-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary btn-wide transition-3d-hover mr-3"
                    onClick={this.handleSubmit}
                  >
                    {this.props.loading ? (
                      <CircularProgress style={{ width: 25, height: 25 }} />
                    ) : (
                      "Send"
                    )}
                  </button>
                  <div className="media-body">
                    <small className="d-flex justify-content-end text-muted">
                      Ou bien appelez nous directement sur le
                    </small>
                    <span className="d-flex justify-content-end text-primary font-weight-semi-bold">
                      +216 71 766 745
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContactForm.propTypes = {
  callback: PropTypes.func,
  loading: PropTypes.bool
};

export default ContactForm;
