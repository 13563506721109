/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getProducts,
  renderProducts,
  changePage,
  nextPage,
  previousPage
} from "../actions/index";

class Pagination extends Component {
  componentDidMount() {
    this.props.getProducts();
  }
  handleClick = e => {
    this.props.changePage(Number(e.target.id));
  };
  handleNext = () => {
    this.props.nextPage(++this.props.products.currentPage);
  };
  handlePrevious = () => {
    this.props.previousPage(--this.props.products.currentPage);
  };

  render() {
    if (!this.props.products) {
      return null;
    } else {
      if (!this.props.products.products || !this.props.products.currentPage) {
        return null;
      } else {
        const pageNumbers = [];
        for (
          let i = 1;
          i <=
          Math.ceil(
            this.props.products.products.length /
              this.props.products.productsPerPage
          );
          i++
        ) {
          pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map(number => {
          return (
            <li
              className={`page-item ml-0 ${
                this.props.products.currentPage === number ? "active " : ""
              }`}
              key={number}
              id={number}
            >
              <a className="page-link" id={number} onClick={this.handleClick}>
                {number}
              </a>
            </li>
          );
        });
        return (
          <div>
            {/* <ul>{renderProducts}</ul> */}
            <div className="d-flex justify-content-between align-items-center">
              <nav aria-label="Page navigation">
                <ul className="pagination mb-0" id="page-numbers">
                  <li className="page-item ml-0">
                    <a
                      className={`page-link ${
                        this.props.products.currentPage === 1
                          ? "btn disabled"
                          : ""
                      } `}
                      onClick={this.handlePrevious}
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">«</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  {renderPageNumbers}
                  <li className="page-item ml-0" aria-label="Next">
                    <a
                      className={`page-link ${
                        this.props.products.currentPage ===
                        Math.ceil(
                          this.props.products.products.length /
                            this.props.products.productsPerPage
                        )
                          ? "btn disabled"
                          : ""
                      } `}
                      onClick={this.handleNext}
                    >
                      <span aria-hidden="true">»</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <small className="d-none d-sm-inline-block text-secondary">
                {`Page ${this.props.products.currentPage} de ${
                  pageNumbers[pageNumbers.length - 1]
                }`}
              </small>{" "}
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(mapStateToProps, {
  getProducts,
  renderProducts,
  previousPage,
  nextPage,
  changePage
})(Pagination);
