import React, { Component } from "react";
import { connect } from "react-redux";

class BelowDescription extends Component {
  render() {
    return (
      <div>
        <div className="mb-4">
          <a href={this.props.products.product.data.file}>
            <button
              type="button"
              className="btn btn-block btn-primary btn-pill transition-3d-hover"
            >
              Télecharger la fiche technique
            </button>
          </a>
        </div>
        <div className="media align-items-center">
          <span id="icon4" className="ie-height-48 w-100 max-width-6 mr-2" />
          <div className="media-body text-secondary small">
            <span className="font-weight-medium mr-1">
              Voir plus sur le site de {this.props.products.product.data.name}
            </span>
            <a
              className="link-muted"
              href={this.props.products.product.data.originalLink}
            >
              Visitez maintenant
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(mapStateToProps, {})(BelowDescription);
