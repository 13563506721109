/**
 *
 * ContactPage
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { postContact } from "../../actions";
import particleImage from "./logo-bubbles.png";
import ParticleEffect from "../../components/ParticleEffect";
import ContactForm from "../../components/ContactForm";

/* eslint-disable react/prefer-stateless-function */
class ContactPage extends React.Component {
  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  updateDimensions = () => {
    this.forceUpdate();
  };

  handleSubmit = payload => {
    console.log(payload);
    this.props.postContact(payload, this.props.history);
  };

  render() {
    console.log(this.props);
    return (
      <div>
        <Helmet>
          <title>ContactPage</title>
          <meta name="description" content="Description of ContactPage" />
        </Helmet>
        <ParticleEffect
          width={50}
          height={75}
          offsetTop={15}
          offsetLeft={40}
          image={particleImage}
        />
        <ContactForm
          loading={this.props.contactPage.loading}
          callback={this.handleSubmit}
        />
      </div>
    );
  }
}

ContactPage.propTypes = {
  postContact: PropTypes.func,
  contactPage: PropTypes.object
};

const mapStateToProps = ({ contactPage }) => ({
  contactPage
});

export default connect(mapStateToProps, { postContact })(ContactPage);
