import React, { Component } from "react";
import ProductRegion from "./ProductRegion";
import SideBar from "../components/SideBar";

export default class ProductMain extends Component {
  render() {
    return (
      <div className="container space-top-1 space-bottom-2">
        <div className="row">
          <ProductRegion />
          <SideBar />
        </div>
      </div>
    );
  }
}
