/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import { firestore } from "../firebase";
import {
  DEFAULT_ACTION,
  FETCH_BLOG_FAILED,
  FETCH_BLOG_START,
  FETCH_BLOG_SUCCESS
} from "./types";

import "moment/locale/fr";
import moment from "moment";
/**
 * Load the repositories, this action starts
 *
 * @return {object} An action object with a type of LOAD_REPOS
 */
const asyncFetchBlogs = () =>
  firestore
    .collection("blog")
    .orderBy("createdAt")
    .get();

export const fetchBlogs = () => async dispatch => {
  try {
    dispatch(loadRepos());

    const { docs } = await asyncFetchBlogs();
    moment.locale("fr");
    const blogs = [];
    docs.map(doc => {
      const docData = doc.data();
      const blog = {
        id: doc.id,
        title: docData.title,
        author: docData.author,
        content: docData.content,
        image: docData.image,
        createdAt: moment(doc.data().createdAt.toDate()).calendar()
      };
      blogs.push(blog);
    });
    dispatch(reposLoaded(blogs));
  } catch (error) {
    dispatch(repoLoadingError(error));
  }
};

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function loadRepos() {
  console.log("rest");
  return {
    type: FETCH_BLOG_START
  };
}

export function reposLoaded(blogs) {
  return {
    type: FETCH_BLOG_SUCCESS,
    payload: blogs
  };
}

export function repoLoadingError(error) {
  return {
    type: FETCH_BLOG_FAILED,
    payload: error
  };
}
