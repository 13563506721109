import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Navbar extends Component {
  render() {
    return (
      <div>
        <header
          id="header"
          className="u-header u-header--sticky-top u-header--show-hide"
          data-header-fix-moment="500"
          data-header-fix-effect="slide"
        >
          <div className="u-header__section">
            <div id="brandlogoAndNav" className="container">
              <nav className="navbar navbar-expand-md u-header__navbar">
                <a
                  className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                  href="/"
                  aria-label="Front"
                >
                  <img
                    src="/assets/svg/logos/Logo-Technolab-_2_-_1_-_1_.svg"
                    style={{ width: "250px" }}
                    alt="NAVBAR"
                  />
                </a>
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navBar"
                  data-toggle="collapse"
                  data-target="#navBar"
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>

                <div
                  id="navBar"
                  className="collapse navbar-collapse u-header__navbar-collapse pb-"
                >
                  <div className="js-scrollbar u-header__navbar-body u-header--abs-top__inner">
                    <ul className="navbar-nav u-header__navbar-nav">
                      <li
                        className="nav-item hs-has-mega-menu u-header__nav-item"
                        data-event="hover"
                        data-animation-in="slideInUp"
                        data-animation-out="fadeOut"
                        data-position="left"
                      >
                        <a
                          id="homeMegaMenu"
                          className="nav-link u-header__nav-link"
                          href="/partenaires"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Partenaires
                        </a>
                      </li>

                      <li
                        className="nav-item hs-has-sub-menu u-header__nav-item"
                        data-event="hover"
                        data-animation-in="slideInUp"
                        data-animation-out="fadeOut"
                      >
                        <a
                          id="pagesMegaMenu"
                          className="nav-link u-header__nav-link"
                          href="https://www.beckmancoulter.com/fr/products"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-labelledby="pagesSubMenu"
                        >
                          Produits
                        </a>
                      </li>

                      <li
                        className="nav-item hs-has-sub-menu u-header__nav-item"
                        data-event="hover"
                        data-animation-in="slideInUp"
                        data-animation-out="fadeOut"
                      >
                        <a
                          id="blogMegaMenu"
                          className="nav-link u-header__nav-link"
                          href="/services"
                          aria-haspopup="true"
                          aria-expanded="false"
                          aria-labelledby="blogSubMenu"
                        >
                          Services
                        </a>
                      </li>

                      <li
                        className="nav-item hs-has-mega-menu u-header__nav-item"
                        data-event="hover"
                        data-animation-in="slideInUp"
                        data-animation-out="fadeOut"
                        data-max-width="440px"
                        data-position="right"
                      >
                        <Link
                          id="shopMegaMenu"
                          className="nav-link u-header__nav-link"
                          to="/app/blogs"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Blog
                        </Link>
                      </li>

                      <li
                        className="nav-item hs-has-mega-menu u-header__nav-item"
                        data-event="hover"
                        data-animation-in="slideInUp"
                        data-animation-out="fadeOut"
                        data-max-width="440px"
                        data-position="right"
                      >
                        <Link
                          id="shopMegaMenu"
                          className="nav-link u-header__nav-link"
                          to="/app/contact"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
