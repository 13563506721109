import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ProductItem extends Component {
  render() {
    return !this.props.product ? null : (
      <div className="card mb-5">
        <Link
          className="js-fancybox u-media-viewer"
          to={`/app/products/${this.props.product.id}`}
          data-src="./assets/img/1920x1080/img36.jpg"
          data-fancybox="fancyboxGallery1"
          data-caption="Front in frames - image #01"
          data-speed="700"
          data-is-infinite="true"
        >
          <img
            className="card-img-top w-100"
            src={this.props.product.data.image}
            alt={this.props.product.data.name}
          />
        </Link>
        <div className="card-body p-4">
          <div className="mb-3">
            <Link
              className="font-size-1"
              style={{ color: "#000" }}
              to={`/app/products/${this.props.product.id}`}
            >
              {this.props.product.data.name}
            </Link>
            <p>
              {this.props.product.data.description.length > 140
                ? this.props.product.data.description.slice(0, 140)
                : this.props.product.data.description}{" "}
              {this.props.product.data.description.length > 140 && (
                <Link to={`/app/products/${this.props.product.id}`}>
                  ...voir plus
                </Link>
              )}
            </p>
          </div>
          <div className="d-flex align-items-center font-size-1">
            <Link
              className="btn btn-sm btn-soft-primary transition-3d-hover ml-auto"
              to={`/app/products/${this.props.product.id}`}
            >
              Détails
              <span className="fas fa-angle-right ml-1" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
