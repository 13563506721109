import React, { Component } from "react";
import ProductCard from "../containers/ProductCard";
import Pagination from "../components/Pagination";
import Spinner from "../components/Spinner";
import { connect } from "react-redux";
import { getProducts } from "../actions/index";

class ProductRegion extends Component {
  componentDidMount() {
    this.props.getProducts();
  }
  render() {
    let productsContent = !this.props.products.productsForCards ? (
      <Spinner />
    ) : this.props.products.productsForCards.length === 0 ? (
      <p>No Products Found</p>
    ) : (
      this.props.products.productsForCards.map((product, index) => (
        <ProductCard
          key={index}
          product1={product.product1}
          product2={product.product2}
        />
      ))
    );
    return (
      <div className="col-lg-8">
        <div className="card-deck d-block d-md-flex">{productsContent}</div>
        <Pagination />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(
  mapStateToProps,
  {
    getProducts
  }
)(ProductRegion);
