import React, { Component } from "react";
import ProductDescription from "../components/ProductDescription";
import Spinner from "../components/Spinner";
import { connect } from "react-redux";
import Accordion from "../components/Accordion";
import BelowDescription from "../components/BelowDescription";

class ProductInfo extends Component {
  render() {
    return !this.props.products.product ? (
      <Spinner />
    ) : (
      <div className="col-lg-5">
        <ProductDescription />
        <Accordion />
        <BelowDescription />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(
  mapStateToProps,
  {}
)(ProductInfo);
