export const GET_PRODUCTS = "GET_PRODUCTS";
export const LOOP_THROUGH_PRODUCTS = "LOOP_THROUGH_PRODUCTS";
export const RENDER_PRODUCTS = "RENDER_PRODUCTS";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const PRODUCTS_PER_PAGE = "PRODUCTS_PER_PAGE";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const GET_BRANDS = "GET_BRANDS";
export const GET_DIAGNOSTICS = "GET_DIAGNOSTICS";
export const GET_INDUSTRIES = "GET_INDUSTRIES";
export const GET_RECHERCHE = "GET_RECHERCHE";
export const GET_EQUIPEMENT = "GET_EQUIPEMENT";
export const GET_PRODUCTS_BY_TYPE = "GET_PRODUCTS_BY_TYPE";
export const GET_PRODUCT = "GET_PRODUCT";
/*
 *
 * ContactPage constants
 *
 */

export const DEFAULT_ACTION = "app/ContactPage/DEFAULT_ACTION";
export const POST_CONTACT_START = "app/ContactPage/POST_CONTACT_START";
export const POST_CONTACT_SUCCESS = "app/ContactPage/POST_CONTACT_SUCCESS";
export const POST_CONTACT_FAILED = "app/ContactPage/POST_CONTACT_FAILED";
/*
 *
 * OneBlogView constants
 *
 */

export const LOAD_ONE_BLOG_START = "app/OneBlogView/LOAD_ONE_BLOG_START";
export const LOAD_ONE_BLOG_SUCCESS = "app/OneBlogView/LOAD_ONE_BLOG_SUCCESS";
export const LOAD_ONE_BLOG_FAILED = "app/OneBlogView/LOAD_ONE_BLOG_FAILED";
/*
 *
 * BlogList constants
 *
 */

export const FETCH_BLOG_START = "app/BlogList/FETCH_BLOG_START";
export const FETCH_BLOG_SUCCESS = "app/BlogList/FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAILED = "app/BlogList/FETCH_BLOG_FAILED";
