import React, { Component } from "react";
import ProductItem from "../components/ProductItem";

export default class ProductCard extends Component {
  render() {
    return !this.props.product2 ? (
      <div className="card-deck d-block d-md-flex">
        <ProductItem product={this.props.product1} />
      </div>
    ) : (
      <div className="card-deck d-block d-md-flex">
        <ProductItem product={this.props.product1} />
        <ProductItem product={this.props.product2} />
      </div>
    );
  }
}
