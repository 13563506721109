/*
 *
 * BlogList reducer
 *
 */

import {
  DEFAULT_ACTION,
  FETCH_BLOG_FAILED,
  FETCH_BLOG_START,
  FETCH_BLOG_SUCCESS
} from "../actions/types";

export const initialState = {
  blogs: [],
  loading: false,
  error: false,
  errorDetails: null,
  loaded: false
};

const blogListReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case FETCH_BLOG_START:
      console.log("rest2");
      return { ...state, loading: true };
    case FETCH_BLOG_SUCCESS:
      return { ...state, loading: false, blogs: action.payload, loaded: true };
    case FETCH_BLOG_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorDetails: action.payload
      };
    default:
      return state;
  }
};

export default blogListReducer;
