/**
 * Firebase Login
 * Reactify comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBCyxfuvh4-r4AFiDY2YbPy3vFZDXy5SEY",
  authDomain: "technolab-5eb66.firebaseapp.com",
  databaseURL: "https://technolab-5eb66.firebaseio.com",
  projectId: "technolab-5eb66",
  storageBucket: "technolab-5eb66.appspot.com",
  messagingSenderId: "140220352559",
  appId: "1:140220352559:web:d984251421ae587e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();
const firestore = firebase.firestore();

export { auth, database, storage, firestore, firebase };
