import React, { Component } from "react";
import { getProduct } from "../actions/";
import { connect } from "react-redux";
import ProductInfo from "../containers/ProductInfo";
import ProductImage from "../containers/ProductImage";

class Product extends Component {
  componentDidMount() {
    this.props.getProduct(this.props.match.params.id);
  }
  render() {
    return (
      <main id="content" role="main">
        <div className="container space-top-1 space-top-sm-2 mt-11">
          <div className="row">
            <ProductImage />
            <ProductInfo />
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(
  mapStateToProps,
  {
    getProduct
  }
)(Product);
