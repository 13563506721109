import React, { Component } from "react";
import { connect } from "react-redux";
import { getProducts, searchProducts } from "../actions";

class Searchbar extends Component {
  state = {
    searchValue: ""
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onSubmit = e => {
    e.preventDefault();
    this.props.searchProducts(this.state.searchValue);
  };
  render() {
    return (
      <div className="bg-light mt-10">
        <div className="container space-1 ">
          <div className="row">
            <div className="col-sm-9 mx-gutters-2">
              <div className="col-lg mb-3 mb-lg-0">
                <div className="js-focus-state">
                  <label className="sr-only" htmlFor="searchPropertySr">
                    Recherche
                  </label>
                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="searchProperty">
                        <span className="fas fa-search" />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="searchValue"
                      id="searchPropertySr"
                      placeholder="Rechercher un produit"
                      aria-label="Rechercher un produit"
                      aria-describedby="searchProperty"
                      value={this.state.searchValue}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <button
                type="submit"
                className="btn btn-sm btn-primary"
                onClick={this.onSubmit}
              >
                Recherchez
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.productsReducer
});

export default connect(mapStateToProps, {
  getProducts,
  searchProducts
})(Searchbar);
